import { type LoaderFunctionArgs, json } from "@remix-run/cloudflare";
import { Outlet } from "@remix-run/react";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({});
};

export default function AuthLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
